import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import {
  MoreVert as MoreVertIcon,
  AttachMoney as MoneyIcon,
  Warning as WarningIcon,
  AccessTime as TimeIcon,
  CalendarToday as CalendarIcon,
  Build as BuildIcon,
} from '@mui/icons-material';
import { formatIndianRupees } from '../utils/currencyFormatter';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import NewsFeed from '../components/NewsFeed';
import moment from 'moment';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { EXPENSE_TYPES } from '../constants/expenses';

const theme = createTheme();

const StatCard = ({ icon: Icon, title, amount, subtitle, color = 'primary' }) => (
  <Card sx={{ height: '100%', bgcolor: 'background.paper' }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: `${color}.lighter`,
            color: `${color}.main`,
            mr: 2,
          }}
        >
          <Icon />
        </Box>
        <Typography variant="h5" component="div">
          {amount}
        </Typography>
      </Box>
      <Typography color="text.secondary" variant="body2">
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
          {subtitle}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const TenantRequest = ({ address, issue, status, date }) => (
  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <BuildIcon sx={{ mr: 2, color: 'text.secondary' }} />
      <Box>
        <Typography variant="subtitle2">{address}</Typography>
        <Typography variant="body2" color="text.secondary">{issue}</Typography>
      </Box>
    </Box>
    <Chip
      label={status}
      color={status === 'OVERDUE' ? 'error' : 'warning'}
      size="small"
      sx={{ textTransform: 'capitalize' }}
    />
  </Box>
);

function Dashboard() {
  const [stats, setStats] = useState({
    rentPaid: 0,
    unpaidExpenses: 0,
    overdueRent: 0,
    upcomingExpenses: 0,
    overdueRequests: 0,
  });
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [overdueServiceRequests, setOverdueServiceRequests] = useState([]);
  const [expenses, setExpenses] = useState([]);

  const { organizationId } = useSelector((state) => state.auth);
  const { properties } = useSelector((state) => state.property);

  const subscribeToPayments = useCallback(async () => {
    if (!organizationId || !properties.length) {
      console.log('Missing organizationId or no properties:', { organizationId, propertiesCount: properties.length });
      setLoading(false);
      return;
    }

    setLoading(true);
    let isSubscribed = true;
    const paymentSubscriptions = [];
    let completedSubscriptions = 0;
    const totalProperties = properties.length;

    properties.forEach((property) => {
      const paymentsQuery = collection(db, 'properties', property.id, 'payments');

      const paymentSub = onSnapshot(paymentsQuery, (paymentSnapshot) => {
        if (!isSubscribed) return;

        const paymentDocs = paymentSnapshot.docs.map((paymentDoc) => ({
          id: paymentDoc.id,
          propertyId: property.id,
          propertyName: property.propertyName,
          ...paymentDoc.data(),
        }));

        setPayments((currentPayments) => {
          const otherPayments = currentPayments.filter((p) => p.propertyId !== property.id);
          const newPayments = [...otherPayments, ...paymentDocs];
          const sortedPayments = newPayments.sort((a, b) =>
            moment(a.dueDate.toDate()).valueOf() - moment(b.dueDate.toDate()).valueOf()
          );
          // console.log('Updated payments state:', sortedPayments.length);

          completedSubscriptions++;
          if (completedSubscriptions === totalProperties) {
            setLoading(false);
          }

          return sortedPayments;
        });
      }, (error) => {
        console.error('Error in payment subscription:', error);
        completedSubscriptions++;
        if (completedSubscriptions === totalProperties) {
          setLoading(false);
        }
      });

      paymentSubscriptions.push(paymentSub);
    });

    return () => {
      isSubscribed = false;
      console.log('Cleaning up payment subscriptions');
      paymentSubscriptions.forEach((unsub) => unsub());
    };
  }, [organizationId, properties]);

  const subscribeToServiceRequests = useCallback(async () => {
    if (!properties) return;

    const unsubscribers = properties.map((property) => {
      const requestsRef = collection(db, 'properties', property.id, 'serviceRequests');

      return onSnapshot(requestsRef, (snapshot) => {
        const propertyOverdue = [];

        snapshot.forEach((doc) => {
          const request = {
            id: doc.id,
            propertyId: property.id,
            propertyName: property.propertyName,
            ...doc.data(),
          };

          const dueDate = moment(request.dueDate.toDate());
          const today = moment().startOf('day');
          if (dueDate.isBefore(today) && request.status !== 'Closed' && request.status !== 'Cancelled') {
            propertyOverdue.push(request);
          }
        });

        setOverdueServiceRequests((prev) => {
          const filtered = prev.filter((req) => req.propertyId !== property.id);
          const updated = [...filtered, ...propertyOverdue];
          // Update the total overdue count after updating the full list
          setStats((prev) => ({ ...prev, overdueRequests: updated.length }));
          return updated;
        });
      });
    });

    return () => unsubscribers.forEach((unsubscribe) => unsubscribe());
  }, [properties]);

  const subscribeToExpenses = useCallback(async () => {
    if (!properties) return;

    const unsubscribers = properties.map((property) => {
      const expensesRef = collection(db, 'properties', property.id, 'expenses');

      const startOfMonth = moment().startOf('month').toDate();
      const endOfMonth = moment().endOf('month').toDate();

      const expensesQuery = query(
        expensesRef,
        where('date', '>=', startOfMonth),
        where('date', '<=', endOfMonth)
      );

      return onSnapshot(expensesQuery, (snapshot) => {
        const propertyExpenses = [];
        snapshot.forEach((doc) => {
          propertyExpenses.push({
            id: doc.id,
            propertyId: property.id,
            propertyName: property.name,
            ...doc.data(),
          });
        });

        setExpenses((prev) => {
          const filtered = prev.filter((expense) => expense.propertyId !== property.id);
          return [...filtered, ...propertyExpenses];
        });
      });
    });

    return () => unsubscribers.forEach((unsubscribe) => unsubscribe());
  }, [properties]);

  useEffect(() => {
    const unsubscribePromises = [
      subscribeToPayments(),
      subscribeToServiceRequests(),
      subscribeToExpenses(),
    ];

    Promise.all(unsubscribePromises).then((unsubscribers) => {
      return () => {
        unsubscribers.forEach((unsubscribe) => unsubscribe && unsubscribe());
      };
    });
  }, [subscribeToPayments, subscribeToServiceRequests, subscribeToExpenses]);

  useEffect(() => {
    // console.log('Calculating stats from payments:', payments.length);
    const now = moment();
    const startOfMonth = now.clone().startOf('month');
    const endOfMonth = now.clone().endOf('month');

    let totalPaidRent = 0;
    let totalOverdueRent = 0;

    payments.forEach((payment) => {
      const paymentDate = moment(payment.dueDate.toDate());
      if (paymentDate.isBetween(startOfMonth, endOfMonth, 'day', '[]')) {
        if (payment.status === 'paid') {
          totalPaidRent += payment.amount;
        } else {
          totalOverdueRent += payment.amount;
        }
      }
    });

    setStats((prev) => ({ ...prev, rentPaid: totalPaidRent, overdueRent: totalOverdueRent }));
  }, [payments]);

  const propertyTypeData = useMemo(() => {
    if (!properties) return [];

    const typeCount = properties.reduce((acc, property) => {
      const type = property.propertyType || 'Other';
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(typeCount).map(([label, value], index) => ({
      id: index,
      value,
      label,
    }));
  }, [properties]);

  const propertyCityData = useMemo(() => {
    if (!properties) return [];

    const cityCount = properties.reduce((acc, property) => {
      const city = property.city || 'Unknown';
      acc[city] = (acc[city] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(cityCount).map(([label, value], index) => ({
      id: index,
      value,
      label,
    }));
  }, [properties]);

  const expensesByType = useMemo(() => {
    const aggregated = {};

    Object.values(EXPENSE_TYPES).forEach((type) => {
      aggregated[type] = 0;
    });

    // console.log('Current expenses:', expenses);

    expenses.forEach((expense) => {
      if (expense.type && expense.amount) {
        const type = expense.type;
        aggregated[type] = (aggregated[type] || 0) + Number(expense.amount);
      }
    });

    // console.log('Aggregated expenses:', aggregated);

    return Object.entries(aggregated)
      .filter(([_, value]) => value > 0)
      .map(([type, value]) => ({
        id: type,
        value,
        label: `${type} (${formatIndianRupees(value)})`,
      }))
      .sort((a, b) => b.value - a.value);
  }, [expenses]);

  const tenantRequests = [
    { address: '277 West 11th Street #2C', issue: 'Broken Window', status: 'IN PROGRESS', date: '2022-01-28' },
    { address: '277 West 11th Street #2C', issue: 'Lock broken', status: 'IN PROGRESS', date: '2022-01-28' },
    { address: '123 Johnson Drive', issue: 'Dishwasher leak', status: 'OVERDUE', date: '2022-01-26' },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ 
        mt: 1, 
        mb: 1, 
        px: { xs: 1, sm: 1, md: 1 },
        height: 'calc(100vh - 88px)', // Subtract AppBar height
        overflow: 'auto'
      }}>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {/* Left Column */}
          <Grid item xs={12} md={9}>
            <Grid container spacing={{ xs: 2, sm: 3 }}>
              {/* Stats Cards */}
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  icon={MoneyIcon}
                  title="Rent Paid"
                  amount={formatIndianRupees(stats.rentPaid)}
                  subtitle={`For ${moment().format('MMMM YYYY')}`}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  icon={TimeIcon}
                  title="Overdue Rent"
                  amount={formatIndianRupees(stats.overdueRent)}
                  subtitle={`For ${moment().format('MMMM YYYY')}`}
                  color="warning"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  icon={WarningIcon}
                  title="Unpaid Expenses"
                  amount={formatIndianRupees(stats.unpaidExpenses)}
                  subtitle="Total unpaid expenses"
                  color="error"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  icon={BuildIcon}
                  title="Overdue Requests"
                  amount={stats.overdueRequests}
                  subtitle="Service requests past due date"
                  color="error"
                />
              </Grid>

              {/* Cashflow Table */}
              <Grid item xs={12}>
                <Card sx={{ height: '100%', bgcolor: 'background.paper' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'primary.lighter',
                          color: 'primary.main',
                          mr: 2,
                        }}
                      >
                        <MoneyIcon />
                      </Box>
                      <Typography variant="h6">
                        Cashflow - {moment().format('MMMM YYYY')}
                      </Typography>
                    </Box>

                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell>Due Date</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {payments
                            .filter((payment) => {
                              const paymentDate = moment(payment.dueDate.toDate());
                              return paymentDate.isBetween(
                                moment().startOf('month'),
                                moment().endOf('month'),
                                'day',
                                '[]'
                              );
                            })
                            .sort((a, b) => moment(a.dueDate.toDate()).valueOf() - moment(b.dueDate.toDate()).valueOf())
                            .map((payment) => (
                              <TableRow key={payment.id}>
                                <TableCell>{payment.propertyName}</TableCell>
                                <TableCell>
                                  {moment(payment.dueDate.toDate()).format('DD MMM YYYY')}
                                </TableCell>
                                <TableCell align="right">
                                  {formatIndianRupees(payment.amount)}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    label={payment.status}
                                    size="small"
                                    color={payment.status === 'paid' ? 'success' : 'warning'}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>

              {/* Main Content Area */}
              <Grid item xs={12}>
                <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ height: '100%' }}>
                  {/* Portfolio Mix */}
                  <Grid item xs={12} md={6}>
                    <Card sx={{ 
                      height: '100%',
                      minHeight: { xs: 350, md: 400 }
                    }}>
                      <CardContent sx={{ height: '100%', p: 2 }}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          mb: 2 
                        }}>
                          <Typography variant="h6">Portfolio Mix</Typography>
                          <IconButton size="small">
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                        <Box sx={{ 
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          height: 'calc(100% - 48px)',
                        }}>
                          {propertyTypeData.length > 0 ? (
                            <>
                              <Box sx={{ width: '100%', height: '100%' }}>
                                <PieChart
                                  series={[
                                    {
                                      data: propertyTypeData,
                                      highlightScope: { faded: 'global', highlighted: 'item' },
                                      faded: { innerRadius: 30, additionalRadius: -30 },
                                      arcLabel: (item) => `${item.value}`,
                                      arcLabelMinAngle: 45,
                                    },
                                  ]}
                                  height={280}
                                  margin={{ top: 10, bottom: 10, left: 10, right: 120 }}
                                  slotProps={{
                                    legend: {
                                      direction: 'column',
                                      position: { vertical: 'middle', horizontal: 'right' },
                                      padding: 0,
                                      itemMarkWidth: 8,
                                      itemMarkHeight: 8,
                                      markGap: 5,
                                      itemGap: 8,
                                      labelStyle: {
                                        fontSize: 12,
                                      },
                                    },
                                  }}
                                />
                              </Box>
                              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, textAlign: 'center' }}>
                                Distribution of {properties.length} properties by type
                              </Typography>
                            </>
                          ) : (
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              height: '100%' 
                            }}>
                              <Typography variant="body2" color="text.secondary">
                                No property data available
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Properties by City */}
                  <Grid item xs={12} md={6}>
                    <Card sx={{ 
                      height: '100%',
                      minHeight: { xs: 350, md: 400 }
                    }}>
                      <CardContent sx={{ height: '100%', p: 2 }}>
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'center',
                          mb: 2 
                        }}>
                          <Typography variant="h6">Properties by City</Typography>
                          <IconButton size="small">
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                        <Box sx={{ 
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          height: 'calc(100% - 48px)',
                        }}>
                          {propertyCityData.length > 0 ? (
                            <>
                              <Box sx={{ width: '100%', height: '100%' }}>
                                <PieChart
                                  series={[
                                    {
                                      data: propertyCityData,
                                      highlightScope: { faded: 'global', highlighted: 'item' },
                                      faded: { innerRadius: 30, additionalRadius: -30 },
                                      arcLabel: (item) => `${item.value}`,
                                      arcLabelMinAngle: 45,
                                    },
                                  ]}
                                  height={280}
                                  margin={{ top: 10, bottom: 10, left: 10, right: 120 }}
                                  slotProps={{
                                    legend: {
                                      direction: 'column',
                                      position: { vertical: 'middle', horizontal: 'right' },
                                      padding: 0,
                                      itemMarkWidth: 8,
                                      itemMarkHeight: 8,
                                      markGap: 5,
                                      itemGap: 8,
                                      labelStyle: {
                                        fontSize: 12,
                                      },
                                    },
                                  }}
                                />
                              </Box>
                              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, textAlign: 'center' }}>
                                Distribution of {properties.length} properties by city
                              </Typography>
                            </>
                          ) : (
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              height: '100%' 
                            }}>
                              <Typography variant="body2" color="text.secondary">
                                No property data available
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              {/* Expenses by Type */}
              <Grid item xs={12} md={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 400,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" color="text.primary">
                      Expenses by Type
                    </Typography>
                    <IconButton size="small">
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                  
                  {expensesByType.length > 0 ? (
                    <Box sx={{ height: 300, width: '100%' }}>
                      <PieChart
                        series={[
                          {
                            data: expensesByType,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30 },
                          },
                        ]}
                        height={300}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ 
                      height: 300, 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center' 
                    }}>
                      <Typography color="text.secondary">
                        No expense data available
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Grid>

              {/* Service Requests Card */}
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" component="h2">
                      Overdue Service Requests
                    </Typography>
                    <IconButton size="small">
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                  {loading ? (
                    <LinearProgress />
                  ) : overdueServiceRequests.length === 0 ? (
                    <Typography variant="body2" color="text.secondary">
                      No overdue service requests
                    </Typography>
                  ) : (
                    <Box>
                      {overdueServiceRequests.slice(0, 5).map((request) => (
                        <TenantRequest
                          key={request.id}
                          address={request.propertyName}
                          issue={request.requestName}
                          status="OVERDUE"
                          date={moment(request.dueDate.toDate()).format('DD MMM YYYY')}
                        />
                      ))}
                      {overdueServiceRequests.length > 5 && (
                        <Typography variant="body2" color="primary" sx={{ mt: 1, cursor: 'pointer' }}>
                          +{overdueServiceRequests.length - 5} more overdue requests
                        </Typography>
                      )}
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Column - News Feed */}
          <Grid item xs={12} md={3}>
            <Box sx={{ 
              height: { md: 'calc(100vh - 108px)' }, // Subtract AppBar height and padding
              overflow: { md: 'auto' }
            }}>
              <NewsFeed />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default Dashboard;
