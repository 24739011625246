import React, { useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  Paper,
  Divider,
  Chip,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import {
  Description as LeaseIcon,
  AccountBalance as FinancialIcon,
  PeopleAlt as SpaceIcon,
  GppGood as ComplianceIcon,
  Build as MaintenanceIcon,
  Nature as SustainabilityIcon,
  Assignment as WorkflowIcon,
  Warning as CriticalIcon,
  Insights as InsightsIcon,
  MoreVert as MoreIcon,
  Snooze as SnoozeIcon,
  Close as DismissIcon,
  Launch as ActionIcon,
} from '@mui/icons-material';
import moment from 'moment';

const notificationConfig = {
  lease: {
    icon: LeaseIcon,
    color: '#2196f3',
  },
  financial: {
    icon: FinancialIcon,
    color: '#4caf50',
  },
  space: {
    icon: SpaceIcon,
    color: '#ff9800',
  },
  compliance: {
    icon: ComplianceIcon,
    color: '#f44336',
  },
  maintenance: {
    icon: MaintenanceIcon,
    color: '#795548',
  },
  sustainability: {
    icon: SustainabilityIcon,
    color: '#4caf50',
  },
  workflow: {
    icon: WorkflowIcon,
    color: '#9c27b0',
  },
  critical: {
    icon: CriticalIcon,
    color: '#d32f2f',
  },
  insights: {
    icon: InsightsIcon,
    color: '#0288d1',
  },
};

// Example notifications data with the new structure
const notifications = [
  {
    id: 1,
    type: 'lease',
    title: 'Lease Expiring Soon',
    message: 'Lease for Tech Hub in Bangalore is expiring in 60 days. Initiate renewal process.',
    timestamp: '2025-01-13T19:14:52+05:30',
    read: true,
    priority: 'high',
    actionUrl: '/properties/tech-hub/lease',
  },
  {
    id: 2,
    type: 'financial',
    title: 'Budget Exceeded',
    message: 'Monthly budget for Bangalore exceeded by ₹50,000. Review expenses.',
    timestamp: '2025-01-13T18:44:52+05:30',
    read: true,
    priority: 'high',
    actionUrl: '/reports/financial',
  },
  {
    id: 3,
    type: 'space',
    title: 'Low Occupancy Alert',
    message: 'Occupancy rate for Startup Hub is below 50% this month. Review space allocation.',
    timestamp: '2025-01-13T15:44:52+05:30',
    read: true,
    priority: 'medium',
    actionUrl: '/properties/startup-hub/occupancy',
  },
  {
    id: 4,
    type: 'compliance',
    title: 'Certification Expiring',
    message: 'Fire safety certification for Business Center One will expire in 30 days.',
    timestamp: '2025-01-13T12:44:52+05:30',
    read: true,
    priority: 'high',
    actionUrl: '/properties/business-center-one/compliance',
  },
  {
    id: 5,
    type: 'maintenance',
    title: 'Scheduled Maintenance',
    message: 'Scheduled HVAC maintenance for Corporate Plaza on Jan 20, 2025. Notify employees.',
    timestamp: '2025-01-12T00:44:52+05:30',
    read: true,
    priority: 'medium',
    actionUrl: '/maintenance/schedule',
  },
];

function NotificationItem({ notification, onDismiss, onSnooze, onAction }) {
  const [snoozeDialogOpen, setSnoozeDialogOpen] = useState(false);

  const handleSnoozeClick = () => {
    setSnoozeDialogOpen(true);
  };

  const handleSnoozeConfirm = (duration) => {
    onSnooze(notification.id, duration);
    setSnoozeDialogOpen(false);
  };

  const NotificationIcon = notificationConfig[notification.type]?.icon || InsightsIcon;

  return (
    <>
      <ListItem
        alignItems="flex-start"
        sx={{
          
          '&:hover': {
            bgcolor: 'action.hover',
          },
          pr: 2,
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: notificationConfig[notification.type]?.color || '#757575' }}>
            <NotificationIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {notification.title}
              {!notification.read && (
                <Chip
                  label="New"
                  size="small"
                  color="primary"
                  sx={{ height: 20 }}
                />
              )}
              <Chip
                label={notification.priority}
                size="small"
                color={notification.priority === 'high' ? 'error' : 'warning'}
                sx={{ height: 20 }}
              />
            </Box>
          }
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                color="text.primary"
                sx={{ display: 'block', mb: 0.5 }}
              >
                {notification.message}
              </Typography>
              <Typography
                component="span"
                variant="caption"
                color="text.secondary"
              >
                {moment(notification.timestamp).fromNow()}
              </Typography>
              <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<ActionIcon />}
                  onClick={() => onAction(notification)}
                >
                  Take Action
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<SnoozeIcon />}
                  onClick={handleSnoozeClick}
                >
                  Snooze
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  startIcon={<DismissIcon />}
                  onClick={() => onDismiss(notification.id)}
                >
                  Dismiss
                </Button>
              </Box>
            </React.Fragment>
          }
        />
      </ListItem>

      <Dialog open={snoozeDialogOpen} onClose={() => setSnoozeDialogOpen(false)}>
        <DialogTitle>Snooze Notification</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={() => handleSnoozeConfirm('1h')}>1 Hour</Button>
            <Button variant="outlined" onClick={() => handleSnoozeConfirm('4h')}>4 Hours</Button>
            <Button variant="outlined" onClick={() => handleSnoozeConfirm('1d')}>1 Day</Button>
            <Button variant="outlined" onClick={() => handleSnoozeConfirm('1w')}>1 Week</Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSnoozeDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Notifications() {
  const [notificationsList, setNotificationsList] = useState(notifications);

  const handleDismiss = (id) => {
    setNotificationsList(prev => prev.filter(n => n.id !== id));
  };

  const handleSnooze = (id, duration) => {
    // In a real app, this would update the notification's snooze time in the backend
    console.log(`Snoozed notification ${id} for ${duration}`);
    setNotificationsList(prev => prev.filter(n => n.id !== id));
  };

  const handleAction = (notification) => {
    // In a real app, this would navigate to the relevant page or open a modal
    console.log('Taking action on notification:', notification);
    window.location.href = notification.actionUrl;
  };

  return (
  
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <List sx={{ width: '100%' }}>
          {notificationsList.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <NotificationItem
                notification={notification}
                onDismiss={handleDismiss}
                onSnooze={handleSnooze}
                onAction={handleAction}
              />
              {index < notificationsList.length - 1 && (
                <Divider variant="inset" component="li" />
              )}
            </React.Fragment>
          ))}
        </List>
      </Paper>
  );
}

export default Notifications;
