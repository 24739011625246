import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Tooltip,
  IconButton,
} from '@mui/material';
import { 
  ExpandMore as ExpandMoreIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';

function AIAnalysis() {
  const {properties, loading: propertiesLoading } = useSelector((state) => state.property);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const [showTransition, setShowTransition] = useState(false);
  const [showResults, setShowResults] = useState(false);
  
  const loadingSteps = [
    "Analyzing the agreement...",
    "Analyzing financials...",
    "Analyzing terms...",
    "Working out recommendations..."
  ];

  useEffect(() => {
    let stepInterval;
    if (loading && loadingStep < loadingSteps.length - 1) {
      stepInterval = setInterval(() => {
        setLoadingStep(prev => {
          if (prev < loadingSteps.length - 1) {
            return prev + 1;
          }
          return prev;
        });
      }, 2000);
    }
    return () => clearInterval(stepInterval);
  }, [loading, loadingStep]);

  const handleAnalyze = async (property) => {
    setSelectedProperty(property);
    setModalOpen(true);
    setLoading(true);
    setLoadingStep(0);
    setShowTransition(false);
    setShowResults(false);
    setError(null);

    try {
      const requestBody = {
        propertyName: property.propertyName,
        propertyType: property.propertyType,
        city: property.city,
        billableSeats: parseInt(property.billableSeats) || 0,
        totalRentPerMonth: parseFloat(property.rentPerMonth) || 0,
        rentPerMonth: Math.round(property.rentPerMonth / property.billableSeats) || 0,
        agreementTerm: parseInt(property.agreementTerm) || 12,
        lockinPeriod: parseInt(property.lockInPeriod) || 0,
        noticePeriod: parseInt(property.noticePeriod) || 0,
        securityDeposit: parseFloat(property.securityDeposit) || 0,
        rentalEscalation: parseFloat(property.rentalEscalation) || 0,
        lockinPeriod: parseInt(property.lockinPeriod) || 0
      };

      const response = await fetch('https://us-central1-gofloaters-property-manager.cloudfunctions.net/analyzePropertyHttp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();
      if (!result.success) {
        throw new Error(result.error || 'Analysis failed');
      }

      setAnalysisResult(result.data);
      setLoading(false);
      setShowTransition(true);
      
      // Show transition message for 1.5 seconds
      setTimeout(() => {
        setShowTransition(false);
        // Wait for fade out before showing results
        setTimeout(() => {
          setShowResults(true);
        }, 500);
      }, 1500);
    } catch (err) {
      setError(err.message || 'Failed to analyze property');
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProperty(null);
    setAnalysisResult(null);
    setError(null);
  };

  const RecommendationItem = ({ recommendation, source }) => (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
      <Typography sx={{ flex: 1 }}>{recommendation}</Typography>
      {source && (
        <Tooltip title={source.replace('Source [', '').replace(']', '')}>
          <IconButton size="small" sx={{ ml: 1, mt: -0.5 }}>
            <DescriptionIcon fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const AnalysisSection = ({ title, data, recommendations = [] }) => (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {Object.entries(data).map(([key, value]) => {
            if (key !== 'recommendations') {
              return (
                <Typography key={key} paragraph>
                  {value}
                </Typography>
              );
            }
            return null;
          })}
          {recommendations.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                Recommendations:
              </Typography>
              {recommendations.map((rec, index) => (
                <RecommendationItem 
                  key={index}
                  recommendation={rec.recommendation}
                  source={rec.source}
                />
              ))}
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {propertiesLoading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {properties.map((property) => (
            <Grid item xs={12} sm={6} md={4} key={property.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {property.propertyName}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    {property.propertyType} • {property.city}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {property.billableSeats} seats • ₹{parseFloat(property.rentPerMonth).toLocaleString()} per month
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAnalyze(property)}
                    fullWidth
                  >
                    Perform AI Analysis
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {selectedProperty ? `Analysing ${selectedProperty.propertyName} Property` : 'AI Analysis'}
        </DialogTitle>
        <DialogContent dividers>
          {loading && (
            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              justifyContent="center" 
              p={3}
              gap={2}
            >
              <CircularProgress 
                size={60}
                sx={{
                  color: 'primary.main',
                  '& .MuiCircularProgress-circle': {
                    strokeLinecap: 'round',
                  },
                }}
              />
              <Typography 
                variant="h6" 
                sx={{ 
                  color: 'primary.main',
                  fontWeight: 500,
                  textAlign: 'center',
                  animation: 'fadeIn 0.5s ease-in',
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'translateY(10px)'
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'translateY(0)'
                    },
                  },
                }}
              >
                {loadingSteps[loadingStep]}
              </Typography>
            </Box>
          )}
          {showTransition && (
            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              justifyContent="center" 
              p={3}
              sx={{
                animation: 'fadeInOut 2s ease-in-out',
                '@keyframes fadeInOut': {
                  '0%': {
                    opacity: 0,
                    transform: 'scale(0.9)'
                  },
                  '20%': {
                    opacity: 1,
                    transform: 'scale(1)'
                  },
                  '80%': {
                    opacity: 1,
                    transform: 'scale(1)'
                  },
                  '100%': {
                    opacity: 0,
                    transform: 'scale(1.1)'
                  },
                },
              }}
            >
              <Typography 
                variant="h5" 
                sx={{ 
                  color: 'primary.main',
                  fontWeight: 600,
                  textAlign: 'center',
                }}
              >
                Now I have everything...
              </Typography>
            </Box>
          )}
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {showResults && analysisResult && (
            <Box sx={{ 
              opacity: 0,
              animation: 'fadeIn 0.5s ease-in forwards',
              '@keyframes fadeIn': {
                '0%': {
                  opacity: 0,
                  transform: 'translateY(20px)'
                },
                '100%': {
                  opacity: 1,
                  transform: 'translateY(0)'
                },
              },
            }}>
              <AnalysisSection
                title="Space Analysis"
                data={analysisResult.spaceAnalysis}
                recommendations={analysisResult.spaceAnalysis.recommendations}
              />
              <AnalysisSection
                title="Financial Analysis"
                data={analysisResult.financialAnalysis}
                recommendations={analysisResult.financialAnalysis.recommendations}
              />
              <AnalysisSection
                title="Terms Analysis"
                data={analysisResult.termsAnalysis}
                recommendations={analysisResult.termsAnalysis.recommendations}
              />
              <AnalysisSection
                title="Market Insights"
                data={analysisResult.marketInsights}
              />
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Overall Recommendation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography paragraph>{analysisResult.overallRecommendation.summary}</Typography>
                  {analysisResult.overallRecommendation.strongPoints.length > 0 && (
                    <>
                      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                        Strong Points:
                      </Typography>
                      {analysisResult.overallRecommendation.strongPoints.map((point, index) => (
                        <RecommendationItem 
                          key={index}
                          recommendation={point.point}
                          source={point.source}
                        />
                      ))}
                    </>
                  )}
                  {analysisResult.overallRecommendation.concernPoints.length > 0 && (
                    <>
                      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                        Concerns:
                      </Typography>
                      {analysisResult.overallRecommendation.concernPoints.map((point, index) => (
                        <RecommendationItem 
                          key={index}
                          recommendation={point.point}
                          source={point.source}
                        />
                      ))}
                    </>
                  )}
                  {analysisResult.overallRecommendation.actionItems.length > 0 && (
                    <>
                      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                        Recommended Actions:
                      </Typography>
                      {analysisResult.overallRecommendation.actionItems.map((item, index) => (
                        <RecommendationItem 
                          key={index}
                          recommendation={item.action}
                          source={item.source}
                        />
                      ))}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AIAnalysis;
