import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Paper,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import moment from 'moment';
import { formatIndianRupees } from '../utils/currencyFormatter';

function PropertyDetailsCard({ property }) {

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Property Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Property Name
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.propertyName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Property Type
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.propertyType}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Micromarket
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.micromarket}, {property.city}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Agreement Period
            </Typography>
            <Typography variant="body1" gutterBottom>
              {moment(property.agreementStartDate).format('MMM D, YYYY')} - 
              {moment(property.agreementEndDate).format('MMM D, YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Operator/Landlord
            </Typography>
            <Typography variant="body1" gutterBottom>
              {property.operatorName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Security Deposit
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatIndianRupees(property.securityDeposit)}
            </Typography>
          </Grid>
          {(property.propertyType === 'Coworking' || property.propertyType === 'Managed Office') && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2" color="text.secondary">
                  Billable Seats
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {property.billableSeats}
                </Typography>
              </Grid>
              {property.propertyType === 'Coworking' && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Rent per Seat
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatIndianRupees(property.rentPerMonth / property.billableSeats)}
                  </Typography>
                </Grid>
              )}
            </>
          )}
          {property.propertyType !== 'Coworking' && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" color="text.secondary">
                Size (sq.ft)
              </Typography>
              <Typography variant="body1" gutterBottom>
                {property.size}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Status
            </Typography>
            <Chip 
              label={property.status}
              color={property.status === 'Active' ? 'success' : 'default'}
              size="small"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Benchmarking() {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [comparisons, setComparisons] = useState([]);
  const [allComparisons, setAllComparisons] = useState([]);
  const [marketStats, setMarketStats] = useState(null);
  const [availableProperties, setAvailableProperties] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { 
    properties, 
    isLoading, 
    error
  } = useSelector((state) => state.property);

  // Operator tier definitions
  const OPERATOR_TIERS = {
    TIER_1: ['WeWork', 'Regus', 'Cowrks', 'The Executive Center', 'Spaces','The Hive'],
    TIER_2: ['Innov8', 'AWFIS', '91Springboard', 'Smartworks', 'Indiqube', 'Bhive',"UrbanVault","Enzyme Offices"],
    TIER_3: ['MyBranch'] // All others will default to Tier 3
  };

  // Helper function to get operator tier
  const getOperatorTier = (operatorName) => {
    if (OPERATOR_TIERS.TIER_1.includes(operatorName)) return 1;
    if (OPERATOR_TIERS.TIER_2.includes(operatorName)) return 2;
    return 3; // Default to Tier 3 for all other operators
  };

  // Calculate similarity score between selected property and matching center
  const calculateSimilarityScore = (selectedProperty, matchingCenter) => {
    let score = 0;
    //City match (25% weight). The API already returns the properties in the same city
    score = 25;
    
    //Micromarket match (25% weight)
    const micromarketMatch = selectedProperty.micromarket.toLowerCase() === matchingCenter.micromarket.toLowerCase();
    if (micromarketMatch) score += 25;

    // Property Type match (25% weight)
    const propertyTypeMatch = selectedProperty.propertyType.toLowerCase() === matchingCenter.operator?.operatorType.toLowerCase();
    if (propertyTypeMatch) score += 25;
    
    // Operator Tier match (25% weight)
    const selectedOperatorTier = getOperatorTier(selectedProperty.operatorName);
    const matchingOperatorTier = getOperatorTier(matchingCenter.operator?.operatorName);
    
    if (selectedOperatorTier === matchingOperatorTier) {
      score += 25; // Full match
    } else if (Math.abs(selectedOperatorTier - matchingOperatorTier) === 1) {
      score += 10; // Adjacent tier (partial match)
    }
    return score;
  };

  useEffect(() => {
    if (selectedProperty) {
      setLoading(true);
      
      // Call the matching centers API
      fetch(`https://us-central1-gofloaters-proposals.cloudfunctions.net/getMatchingCenters?city=${selectedProperty.city}&micromarket=${selectedProperty.micromarket}`)
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success' && data.matchingCenters) {
            // Transform the matching centers into our market data format
            const key = `${selectedProperty.city}-${selectedProperty.micromarket}-${selectedProperty.propertyType}`;
            const marketProperties = data.matchingCenters.map(center => ({
              propertyName: center.centerName,
              rentPerSeat: center.listPrice,
              location: center.locality,
              micromarket: center.micromarket || '',
              operatorName: center.operator?.operatorName || '',
              operatorType: center.operator?.operatorType || '',
              propertyType: center.propertyType || '',
              size: center.size,
              securityDeposit: center.securityDeposit,
              agreementStartDate: center.agreementStartDate,
              amenities: center.amenities || [],
              rating: 4.5, // Default rating since API doesn't provide it
              similarityScore: calculateSimilarityScore(selectedProperty, center)
            }));

            // Sort by similarity score in descending order
            marketProperties.sort((a, b) => b.similarityScore - a.similarityScore);

            // Set the comparisons
            setAllComparisons(marketProperties);
            //Filter the properties that have similarityScore of 60 or above
            setComparisons(marketProperties.filter(property => property.similarityScore >= 60));
            // setComparisons(marketProperties.slice(0, 10));


          } else {
            setComparisons([]);
            setAllComparisons([]);

          }
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching matching centers:', error);
          setComparisons([]);
          setAllComparisons([]);
          setMarketStats(null);
          setLoading(false);
        });
    }
  }, [selectedProperty]);

  useEffect(() => {
    // Calculate the market stats when comparisons change
    if (comparisons.length > 0) {
      const totalRent = comparisons.reduce((acc, comparison) => acc + Number(comparison.rentPerSeat), 0);
      const avgRent = totalRent / comparisons.length;
      const diff = (selectedProperty.rentPerMonth / selectedProperty.billableSeats) - avgRent;
      const diffPercentage = diff / (selectedProperty.rentPerMonth / selectedProperty.billableSeats) * 100;
      setMarketStats({ avgRent, diff, diffPercentage, status: getComparisonStatus(diff) });
    } else {
      setMarketStats(null);
    }
  }, [comparisons]);

  const handlePropertyChange = (event, newValue) => {
    setSelectedProperty(newValue);
    setMarketStats(null);
    setComparisons([]);
    setAllComparisons([]);
  };

  const getComparisonStatus = (diff) => {
    if (!diff && diff !== 0) return { label: 'No Data', color: 'default' };
    if (diff <= 0) return { label: 'Below Market', color: 'success' };
    if (diff >= 0) return { label: 'Above Market', color: 'error' };
    return { label: 'At Market', color: 'primary' };
  };

  const isConventionalOffice = selectedProperty?.propertyType === 'Conventional Office';

  const renderMarketPosition = () => {
    if (!marketStats) {
      return (
        <Grid item xs={12}>
          <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Market Position
              </Typography>
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <Typography variant="body1" color="text.secondary">
                  Benchmarking data is not available for this property yet.
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Grid>
      );
    }

    const diff = isConventionalOffice ? marketStats.rentPerSqFtDiff : marketStats.rentPerSeatDiff;
    const average = isConventionalOffice ? marketStats.avgRentPerSqFt : marketStats.avgRentPerSeat;
    const currentValue = isConventionalOffice ? selectedProperty.rentPerSqFt : selectedProperty.rentPerSeat;

    return (
      <Grid item xs={12}>
        <Box sx={{ p: 3 }}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Market Position
            </Typography>
            <Grid container spacing={2}>
              {selectedProperty.propertyType === 'Coworking' ? (
                <>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Current Rent per Seat</Typography>
                        <Typography variant="h5">
                          {selectedProperty && formatIndianRupees(selectedProperty.rentPerMonth / selectedProperty.billableSeats)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Average Rent per Seat</Typography>
                        <Typography variant="h5">
                          {marketStats ? formatIndianRupees(marketStats.avgRent) : 'N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Position</Typography>
                        <Typography variant="h5" color={marketStats.diffPercentage > 0 ? 'error.main' : marketStats.diffPercentage < 0 ? 'success.main' : 'text.primary'}>
                          {marketStats.diffPercentage ? `${marketStats.diffPercentage > 0 ? '+' : ''}${marketStats.diffPercentage.toFixed(1)}%` : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {marketStats.diffPercentage > 0 ? 'Above' : marketStats.diffPercentage < 0 ? 'Below' : 'At'} Market Average
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Current Rent</Typography>
                        <Typography variant="h5">
                          {selectedProperty && formatIndianRupees(selectedProperty.rentPerMonth)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Average Rent</Typography>
                        <Typography variant="h5">
                          {marketStats ? formatIndianRupees(average * (isConventionalOffice ? selectedProperty.size : selectedProperty.billableSeats)) : 'N/A'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <Typography variant="subtitle1">Market Position</Typography>
                        <Typography variant="h5" color={diff > 0 ? 'error.main' : diff < 0 ? 'success.main' : 'text.primary'}>
                          {diff ? `${diff > 0 ? '+' : ''}${diff.toFixed(1)}%` : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {diff > 0 ? 'Above' : diff < 0 ? 'Below' : 'At'} Market Average
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Box>
      </Grid>
    );
  };

  const handleAddToCompare = () => {
    const propertiesInComparison = comparisons.map(comp => comp.propertyName);
    const propertiesToAdd = allComparisons.filter(prop => !propertiesInComparison.includes(prop.propertyName));
    setAvailableProperties(propertiesToAdd);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const addPropertyToComparison = (property) => {
    // Add the selected property to the comparisons
    setComparisons([...comparisons, property]);
    console.log('Adding property to comparison:', property);
    // Close the dialog after adding the property
    handleCloseDialog();
  };

  return (
    <Container 
      maxWidth={false} 
      disableGutters
      sx={{ height: '100%' }}
    >
      <Paper 
        sx={{ 
          height: '100%',
          borderRadius: 0,
        }}
      >
        <Box sx={{ px: 3, pt: 3 }}>
          <Autocomplete
            options={properties}
            getOptionLabel={(option) => `${option.propertyName} - ${option.locality}, ${option.city}`}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Search Properties" 
                variant="outlined" 
                fullWidth 
              />
            )}
            value={selectedProperty}
            onChange={(event, newValue) => {
              setSelectedProperty(newValue);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body1">{option.propertyName}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {option.locality}, {option.city}
                  </Typography>
                </Box>
              </li>
            )}
          />
        </Box>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && !selectedProperty && (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="body1">
              Select a property to view benchmarking details
            </Typography>
          </Box>
        )}

        {!loading && selectedProperty && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box sx={{ p: 3 }}>
                <PropertyDetailsCard property={selectedProperty} />
              </Box>
            </Grid>

            {selectedProperty && renderMarketPosition()}

            {!loading && selectedProperty && comparisons && comparisons.length > 0 && (
              <Grid item xs={12}>
                <Box sx={{ p: 3 }}>
                  <Paper sx={{ p: 2 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Typography variant="h6" gutterBottom>
                          Comparable Properties
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" onClick={handleAddToCompare}>
                          Add to Compare
                        </Button>
                      </Grid>
                    </Grid>
                    {comparisons && comparisons.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell>Operator Name</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Type</TableCell>
                            {selectedProperty.propertyType === 'Coworking' ? (
                              <TableCell>Rent/Seat</TableCell>
                            ) : (
                              <TableCell>Rent/Sq.Ft</TableCell>
                            )}
                            <TableCell>Similarity Score</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {comparisons.map((comparison, index) => (
                            <TableRow key={index}>
                              <TableCell>{comparison.propertyName || 'N/A'}</TableCell>
                              <TableCell>{comparison.operatorName || 'N/A'}</TableCell>
                              <TableCell>{comparison.location || 'N/A'}</TableCell>
                              <TableCell>{comparison.operatorType || 'N/A'}</TableCell>
                              <TableCell>
                                {selectedProperty.propertyType === 'Coworking'
                                  ? formatIndianRupees(comparison.rentPerSeat || 0)
                                  : `₹${comparison.rentPerSqFt || 'N/A'}`}
                              </TableCell>
                              <TableCell>
                                <Chip color="primary"
                                    size="small" label={`${comparison.similarityScore || 0}%`} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    ) : (
                      <Box sx={{ textAlign: 'center', py: 3 }}>
                        <Typography variant="body1" color="text.secondary">
                          Benchmarking data is not available for this property yet.
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md">
          <DialogTitle>Available Properties to Compare</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {availableProperties.map((property, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Card sx={{ height: '100%' }}>
                    <CardContent>
                      <Typography variant="body1">{property.propertyName}</Typography>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="body2" color="text.secondary">
                        {property.location}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {property.similarityScore}%
                      </Typography>
                      </Box>
                      <Button variant="outlined" color="primary" onClick={() => addPropertyToComparison(property)}>
                        Add
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
}

export default Benchmarking;
