import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  TextField,
} from '@mui/material';
import {
  DownloadOutlined as DownloadIcon,
  OpenInNew as OpenIcon,
} from '@mui/icons-material';

// Hardcoded reports based on CBRE insights
const industryReports = [
  {
    id: 1,
    title: 'India Real Estate Market Outlook 2024',
    description: 'Comprehensive analysis of real estate trends and market dynamics in India.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Market+Outlook+2024',
    pdfUrl: 'https://example.com/reports/india-real-estate-2024.pdf',
  },
  {
    id: 2,
    title: 'Bangalore Office Market Trends',
    description: 'Detailed insights into Bangalore\'s commercial real estate landscape.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Bangalore+Office+Market',
    pdfUrl: 'https://example.com/reports/bangalore-office-market.pdf',
  },
  {
    id: 3,
    title: 'Residential Property Investment Guide',
    description: 'Strategic guide for residential property investments across major Indian cities.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Residential+Investment+Guide',
    pdfUrl: 'https://example.com/reports/residential-investment-guide.pdf',
  },
  {
    id: 4,
    title: 'Commercial Real Estate Trends Q4 2023',
    description: 'Quarterly analysis of commercial real estate performance and projections.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Commercial+RE+Trends',
    pdfUrl: 'https://example.com/reports/commercial-trends-q4-2023.pdf',
  },
  {
    id: 5,
    title: 'Coworking Space Market Evolution',
    description: 'Emerging trends in coworking and flexible workspace solutions.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Coworking+Market',
    pdfUrl: 'https://example.com/reports/coworking-market-evolution.pdf',
  },
  {
    id: 6,
    title: 'Retail Real Estate Recovery Insights',
    description: 'Post-pandemic recovery and transformation of retail real estate.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Retail+Real+Estate',
    pdfUrl: 'https://example.com/reports/retail-real-estate-recovery.pdf',
  },
  {
    id: 7,
    title: 'Industrial and Logistics Property Outlook',
    description: 'Growth potential in industrial and logistics real estate segments.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Industrial+Logistics',
    pdfUrl: 'https://example.com/reports/industrial-logistics-outlook.pdf',
  },
  {
    id: 8,
    title: 'Sustainable Real Estate Developments',
    description: 'Green building trends and sustainable property development strategies.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Sustainable+RE',
    pdfUrl: 'https://example.com/reports/sustainable-real-estate.pdf',
  },
  {
    id: 9,
    title: 'Technology Impact on Real Estate',
    description: 'How emerging technologies are reshaping the real estate landscape.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Tech+in+RE',
    pdfUrl: 'https://example.com/reports/technology-real-estate.pdf',
  },
  {
    id: 10,
    title: 'Investment Attractiveness Index',
    description: 'Comparative analysis of real estate investment opportunities across regions.',
    imageUrl: 'https://via.placeholder.com/300x200?text=Investment+Index',
    pdfUrl: 'https://example.com/reports/investment-attractiveness.pdf',
  },
];

function IndustryReports() {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter reports based on search term
  const filteredReports = industryReports.filter(report => 
    report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    report.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownload = (pdfUrl) => {
    // Placeholder for download functionality
    window.open(pdfUrl, '_blank');
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Industry Reports
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          label="Search Reports"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 3 }}
        />

        <Grid container spacing={3}>
          {filteredReports.map((report) => (
            <Grid item xs={12} sm={6} md={4} key={report.id}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.03)',
                }
              }}>
                {/* <CardMedia
                  component="img"
                  height="200"
                  image={report.imageUrl}
                  alt={report.title}
                /> */}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div">
                    {report.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {report.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button 
                    size="small" 
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(report.pdfUrl)}
                  >
                    Download
                  </Button>
                  <Button 
                    size="small" 
                    startIcon={<OpenIcon />}
                    onClick={() => window.open(report.pdfUrl, '_blank')}
                  >
                    Open
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
}

export default IndustryReports;
