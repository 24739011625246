import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
  IconButton,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Popover,
  TextField,
  MenuItem,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  AppBar,
  Paper,
  Toolbar,
} from '@mui/material';
import {
  ErrorOutline as HighIcon,
  WarningAmber as MediumIcon,
  Info as LowIcon,
  Close as CloseIcon,
  CheckCircle as BenefitIcon,
  Warning as RiskIcon,
  PlaylistAddCheck as ActionIcon,
  Business as PropertyIcon,
  AttachMoney as CostIcon,
  Build as ServiceIcon,
  Description as LeaseIcon,
  Schedule as ScheduleIcon,
  PlaylistAdd as PlaylistAddIcon,
  BlockOutlined as BlockIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import MeetingScheduler from './MeetingScheduler';

const priorityConfig = {
  high: {
    icon: HighIcon,
    color: '#d32f2f',
    label: 'High Priority',
    description: 'Immediate action recommended',
  },
  medium: {
    icon: MediumIcon,
    color: '#ed6c02',
    label: 'Medium Priority',
    description: 'Action required within 3 months',
  },
  low: {
    icon: LowIcon,
    color: '#2e7d32',
    label: 'Low Priority',
    description: 'Consider implementing when convenient',
  },
};

const typeConfig = {
  cost: {
    icon: CostIcon,
    color: '#2196f3',
    label: 'Cost',
    description: 'Cost optimization opportunity',
  },
  service: {
    icon: ServiceIcon,
    color: '#9c27b0',
    label: 'Service',
    description: 'Service quality improvement',
  },
  lease: {
    icon: LeaseIcon,
    color: '#4caf50',
    label: 'Lease',
    description: 'Lease management action',
  },
};

const RecommendationDetails = ({ recommendation, open, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);

  if (!recommendation) return null;

  const {
    title,
    description,
    priority,
    type,
    effort,
    impact,
    createdAt,
    dueDate,
    properties,
    kpis,
    nextSteps,
    action,
    actionSteps,
    benefits,
    risks,
  } = recommendation;

  const PriorityIcon = priorityConfig[priority]?.icon || MediumIcon;
  const TypeIcon = typeConfig[type]?.icon || LowIcon;

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleScheduleCall = () => {
    handlePopoverClose();
    setScheduleDialogOpen(true);
  };

  const handleAddToActionItems = async () => {
    try {
      await addDoc(collection(db, 'actionItems'), {
        title: `Review recommendation: ${recommendation.title}`,
        description: recommendation.description,
        status: 'pending',
        createdAt: new Date(),
        dueDate: moment().add(7, 'days').toDate(),
        priority: 'medium',
        type: 'recommendation',
        recommendationId: recommendation.id,
      });
      handlePopoverClose();
    } catch (error) {
      console.error('Error adding action item:', error);
    }
  };

  const handleIgnore = async () => {
    try {
      await updateDoc(doc(db, 'recommendations', recommendation.id), {
        status: 'ignored',
        updatedAt: new Date(),
      });
      handlePopoverClose();
      onClose();
    } catch (error) {
      console.error('Error updating recommendation:', error);
    }
  };

  const handleMeetingScheduled = async (meeting) => {
    try {
      await updateDoc(doc(db, 'recommendations', recommendation.id), {
        status: 'meeting_scheduled',
        meetingId: meeting.id,
        updatedAt: new Date(),
      });
      onClose();
    } catch (error) {
      console.error('Error updating recommendation:', error);
    }
  };

  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 2 }}>
              <PriorityIcon sx={{ color: priorityConfig[priority]?.color }} />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {title}
              </Typography>
              <IconButton onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

        {/* Content */}
        <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">{description}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gap={2}>
                <Chip 
                  icon={<TypeIcon />}
                  label={typeConfig[type]?.label || 'Type'}
                  color="primary"
                  variant="outlined"
                />
                <Chip 
                  label={`${effort || 'Medium'} Effort`}
                  color={(effort || '').toLowerCase() === 'low' ? 'success' : (effort || '').toLowerCase() === 'medium' ? 'warning' : 'error'}
                />
                <Chip 
                  label={`${impact || 'Medium'} Impact`}
                  color={(impact || '').toLowerCase() === 'low' ? 'success' : (impact || '').toLowerCase() === 'medium' ? 'warning' : 'error'}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Timeline
              </Typography>
              <Box display="flex" gap={2}>
                <Typography variant="body2">
                  Created: {moment(createdAt).format('MMM D, YYYY')}
                </Typography>
                <Typography variant="body2">
                  Due: {moment(dueDate).format('MMM D, YYYY')}
                </Typography>
              </Box>
            </Grid>

            {properties && properties.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Properties Affected
                </Typography>
                <List dense>
                  {properties.map((property, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <PropertyIcon color="action" />
                      </ListItemIcon>
                      <ListItemText primary={property} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {kpis && kpis.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  KPIs to Monitor
                </Typography>
                <List dense>
                  {kpis.map((kpi, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <BenefitIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={kpi} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {nextSteps && nextSteps.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Next Steps
                </Typography>
                <List dense>
                  {nextSteps.map((step, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <ActionIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={step} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {actionSteps && actionSteps.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Action Steps
                </Typography>
                <List dense>
                  {actionSteps.map((step, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <ActionIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary={step} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {benefits && benefits.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Benefits
                </Typography>
                <List dense>
                  {benefits.map((benefit, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <BenefitIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary={benefit} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {risks && risks.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Risks
                </Typography>
                <List dense>
                  {risks.map((risk, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <RiskIcon color="error" />
                      </ListItemIcon>
                      <ListItemText primary={risk} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
        </Box>

        {/* Footer Actions */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 2, 
            display: 'flex', 
            justifyContent: 'flex-start',
            gap: 2,
            borderTop: 1,
            borderColor: 'divider'
          }}
        >
          <Button
            startIcon={<ScheduleIcon />}
            onClick={handleScheduleCall}
            variant="contained"
            color="primary"
          >
            Schedule Call with Account Manager
          </Button>
        </Paper>
      </Box>

      <MeetingScheduler
        open={scheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        title="Schedule Call with Account Manager"
        description={`Discuss recommendation: ${title}\n\n${description}`}
        meetingType="recommendation_review"
        referenceId={recommendation.id}
        onScheduled={handleMeetingScheduled}
      />
    </Paper>
  );
};

export default RecommendationDetails;
