import React, { useState, useContext } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Badge,
  Divider,
  Collapse,
  Avatar,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Business as PropertyIcon,
  Assessment as ReportIcon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  AccountCircle as AccountIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
  Notifications as NotificationsIcon,
  Payment as PaymentIcon,
  Lightbulb as RecommendationsIcon,
  TrendingUp as BenchmarkingIcon,
  ExpandLess,
  ExpandMore,
  Analytics as AnalyticsIcon,
  Timeline as TimelineIcon,
  AutoGraph as GraphIcon,
  Article as ArticleIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Folder as FolderIcon,
  AccountBalance as FinanceIcon,
  Receipt as ReceiptIcon,
  Engineering as FacilitiesIcon,
  Build as ServiceIcon,
  Gavel as ComplianceIcon,
  Phone as CallIcon,
  Handyman as ToolsIcon,
  Insights as InsightsIcon,
  Compare as CompareIcon,
  Psychology as AIIcon,
  AutoAwesome as AIAssistantIcon,
} from '@mui/icons-material';
import { logout } from '../store/slices/authSlice';
import { ColorModeContext } from '../App';
import MeetingScheduler from './MeetingScheduler';
import ChatButton from './ChatButton';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const drawerWidth = 240;

const menuItems = [
  { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
  { text: 'Properties', icon: <PropertyIcon />, path: '/properties' },
  {
    text: 'Finance',
    icon: <FinanceIcon />,
    path: '/finance',
    subItems: [
      {
        text: 'Rent',
        path: '/payments',
        icon: <PaymentIcon />,
      },
      {
        text: 'Expenses',
        path: '/expenses',
        icon: <ReceiptIcon />,
      }
    ],
  },
  {
    text: 'Facilities',
    icon: <FacilitiesIcon />,
    path: '/facilities',
    subItems: [
      {
        text: 'Service Requests',
        path: '/service-requests',
        icon: <ServiceIcon />,
      },
      {
        text: 'Compliance',
        path: '/compliance',
        icon: <ComplianceIcon />,
      },
    ],
  },
  {
    text: 'Tools',
    icon: <ToolsIcon />,
    path: '/tools',
    subItems: [
      {
        text: 'Document Vault',
        path: '/document-vault',
        icon: <FolderIcon />,
      },
      {
        text: 'Compare Properties',
        path: '/compare-properties',
        icon: <CompareIcon />,
      },
      {
        text: 'AI Analysis',
        path: '/ai-analysis',
        icon: <AIIcon />,
      },
    ],
  },
  {
    text: 'Insights',
    icon: <InsightsIcon />,
    path: '/insights',
    subItems: [
      {
        text: 'Recommendations',
        path: '/recommendations',
        icon: <RecommendationsIcon />,
      },
      {
        text: 'Benchmarking',
        path: '/benchmarking',
        icon: <BenchmarkingIcon />,
      },
      {
        text: 'Industry Insights',
        path: '/industry-reports',
        icon: <AnalyticsIcon />,
      },
    ],
  },
  {
    text: 'Reports',
    icon: <ReportIcon />,
    path: '/reports',
    subItems: [
      {
        text: 'Portfolio Summary',
        path: '/reports/portfolio-summary',
        icon: <AnalyticsIcon />,
      },
      {
        text: 'Cash Flow Analysis',
        path: '/reports/rent-renewal-analysis',
        icon: <GraphIcon />,
      },
    ],
  },
  // { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
];

function Layout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(3); // Example count
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { fullName } = useSelector((state) => state.auth);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = (menuName) => {
    setExpandedMenu(expandedMenu === menuName ? null : menuName);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      dispatch(logout()); // Clear Redux store
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const getCurrentScreenName = () => {
    const path = location.pathname;
    const currentItem = menuItems.find(item => {
      if (item.path === path) return true;
      if (item.subItems) {
        return item.subItems.some(subItem => subItem.path === path);
      }
      return false;
    });

    if (currentItem) {
      if (currentItem.subItems) {
        const subItem = currentItem.subItems.find(item => item.path === path);
        return subItem ? subItem.text : currentItem.text;
      }
      return currentItem.text;
    }
    return 'Dashboard';
  };

  const handleNotificationsClick = (event) => {
    navigate('/notifications');
  };

  const drawer = (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
    }}>
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}>
        <img 
          src="https://assets.gofloaters.com/logo.png" 
          alt="GoFloaters Logo" 
          style={{ 
            height: '30px',
            width: 'auto',
            objectFit: 'contain'
          }} 
        />
      </Box>
      <Divider />
      <List sx={{ flexGrow: 1, px: 2 }}>
        {menuItems.map((item) => (
          <React.Fragment key={item.text}>
            {item.subItems ? (
              <div>
                <ListItem
                  button
                  onClick={() => handleMenuClick(item.text)}
                  sx={{
                    borderRadius: '12px',
                    mb: 1,
                    '&:hover': {
                      backgroundColor: theme.palette.mode === 'light' 
                        ? 'rgba(59, 130, 246, 0.08)'
                        : 'rgba(255, 255, 255, 0.08)',
                    },
                  }}
                >
                  <ListItemIcon sx={{
                    minWidth: 40,
                    color: window.location.pathname === item.path 
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.text}
                    sx={{
                      '& .MuiTypography-root': {
                        color: window.location.pathname === item.path 
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                      }
                    }}
                  />
                  {expandedMenu === item.text ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={expandedMenu === item.text}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItem
                        key={subItem.text}
                        button
                        onClick={() => handleNavigation(subItem.path)}
                        sx={{
                          pl: 4,
                          borderRadius: '12px',
                          mb: 1,
                          '&:hover': {
                            backgroundColor: theme.palette.mode === 'light' 
                              ? 'rgba(59, 130, 246, 0.08)'
                              : 'rgba(255, 255, 255, 0.08)',
                          },
                        }}
                      >
                        <ListItemIcon sx={{
                          minWidth: 40,
                          color: window.location.pathname === subItem.path 
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        }}>
                          {subItem.icon}
                        </ListItemIcon>
                        <ListItemText 
                          primary={subItem.text}
                          sx={{
                            '& .MuiTypography-root': {
                              color: window.location.pathname === subItem.path 
                                ? theme.palette.primary.main
                                : theme.palette.text.primary,
                            }
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </div>
            ) : (
              <ListItem
                button
                onClick={() => handleNavigation(item.path)}
                sx={{
                  borderRadius: '12px',
                  mb: 1,
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'light' 
                      ? 'rgba(59, 130, 246, 0.08)'
                      : 'rgba(255, 255, 255, 0.08)',
                  },
                }}
              >
                <ListItemIcon sx={{
                  minWidth: 40,
                  color: window.location.pathname === item.path 
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text}
                  sx={{
                    '& .MuiTypography-root': {
                      color: window.location.pathname === item.path 
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    }
                  }}
                />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap: 1,
        bgcolor: 'background.paper',
        p: 2,
        borderTop: 1,
        borderColor: 'divider',
        mt: 'auto'
      }}>
        <IconButton 
          size="small"
          onClick={() => navigate('/account')}
        >
          <AccountIcon />
        </IconButton>
        <IconButton 
          size="small"
          onClick={() => navigate('/settings')}
        >
          <SettingsIcon />
        </IconButton>
        <IconButton 
          size="small"
          onClick={handleLogout}
        >
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          borderRadius: 0,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {getCurrentScreenName()}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton 
            color="inherit" 
            onClick={() => setScheduleDialogOpen(true)}
            sx={{ mr: 1 }}
          >
            <CallIcon />
          </IconButton>
          <ChatButton 
            IconComponent={AIAssistantIcon}
            sx={{ 
              mr: 1,
              color: 'inherit'
            }} 
          />
          <IconButton 
            color="inherit"
            onClick={handleNotificationsClick}
            sx={{ mr: 1 }}
          >
            <Badge badgeContent={unreadNotifications} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      
      <Box
        component="nav"
        sx={{ 
          width: { sm: drawerWidth }, 
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              borderRadius: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              borderRadius: 0,
              border: 'none',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: theme.palette.background.default,
          minHeight: '100vh',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      <MeetingScheduler
        open={scheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        title="Schedule Account Manager Call"
        description="Schedule a call with your account manager"
        meetingType="general"
        onScheduled={() => setScheduleDialogOpen(false)}
      />
    </Box>
  );
}

export default Layout;
