import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { collection, query, where, doc, updateDoc, addDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Toolbar,
  Divider,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  IconButton,
  Grid,
  Chip,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
} from '@mui/material';
import {
  Add as AddIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  Search as SearchIcon,
  ViewList as ListView,
  GridView as GridViewIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Business as BusinessIcon,
  FileUpload as FileUploadIcon,
} from '@mui/icons-material';
import { formatIndianRupees } from '../utils/currencyFormatter';
import ImportAgreementDialog from '../components/ImportAgreementDialog';
import PropertyMap from '../components/PropertyMap';
import {
  setSelectedProperty,
  clearSelectedProperty,
  setEditDialogOpen,
  addProperty,
  updateProperty,
  setError
} from '../redux/reducers/propertyReducer';

const PROPERTY_TYPES = {
  COWORKING: 'Coworking',
  MANAGED_OFFICE: 'Managed Office',
  CONVENTIONAL_OFFICE: 'Conventional Office'
};

const columns = [
  { id: 'propertyName', label: 'Property Name', minWidth: 170 },
  { id: 'propertyType', label: 'Property Type', minWidth: 130 },
  { id: 'city', label: 'City', minWidth: 100 },
  { 
    id: 'rentPerMonth', 
    label: 'Rent/Month', 
    minWidth: 120,
    format: (value) => formatIndianRupees(value),
  },
  { id: 'locality', label: 'Locality', minWidth: 130 },
  { 
    id: 'agreementStartDate', 
    label: 'Agreement Start Date',
    minWidth: 150,
    format: (value) => new Date(value).toLocaleDateString(),
  },
  { 
    id: 'agreementEndDate', 
    label: 'Agreement End Date',
    minWidth: 150,
    format: (value) => new Date(value).toLocaleDateString(),
  },
  { id: 'operatorName', label: 'Operator / Landlord Name', minWidth: 170 },
  { 
    id: 'securityDeposit', 
    label: 'Security Deposit',
    minWidth: 130,
    format: (value) => formatIndianRupees(value),
  },
  { 
    id: 'billableSeats', 
    label: 'Billable Seats',
    minWidth: 120,
    format: (value) => value.toString(),
  },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'location', label: 'Location', minWidth: 150 },
];

// TabPanel component for tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`property-tabpanel-${index}`}
      aria-labelledby={`property-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function Properties() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [view, setView] = useState('list');
  const [selectedTab, setSelectedTab] = useState(0);
  const [saving, setSaving] = useState(false);
  const [isNewProperty, setIsNewProperty] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('propertyName');
  const [order, setOrder] = useState('asc');
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('success');

  // Redux state
  const organizationId = useSelector((state) => state.auth?.organizationId);
  const { 
    properties, 
    loading, 
    error, 
    selectedProperty, 
    editDialogOpen
  } = useSelector((state) => state.property);

  // Filtered and sorted properties
  const filteredProperties = useMemo(() => {
    return properties
      .filter(property => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return (
          property.propertyName?.toLowerCase().includes(searchLower) ||
          property.city?.toLowerCase().includes(searchLower) ||
          property.locality?.toLowerCase().includes(searchLower) ||
          property.operatorName?.toLowerCase().includes(searchLower)
        );
      })
      .sort((a, b) => {
        const isAsc = order === 'asc';
        if (orderBy === 'rentPerMonth' || orderBy === 'securityDeposit' || orderBy === 'billableSeats') {
          return isAsc ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        }
        return isAsc
          ? (a[orderBy] || '').localeCompare(b[orderBy] || '')
          : (b[orderBy] || '').localeCompare(a[orderBy] || '');
      });
  }, [properties, searchQuery, orderBy, order]);

  // Pagination
  const paginatedProperties = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return filteredProperties.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredProperties, page, rowsPerPage]);

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddProperty = () => {
    dispatch(clearSelectedProperty());
    dispatch(setEditDialogOpen(true));
  };

  const handleEditProperty = (property) => {
    setIsNewProperty(false);
    dispatch(setSelectedProperty(property));
  };

  const handleCloseDialog = () => {
    dispatch(setEditDialogOpen(false));
  };

  const handleSaveProperty = async (propertyData) => {
    try {
      setSaving(true);
      
      if (isNewProperty) {
        const docRef = await addDoc(collection(db, 'properties'), {
          ...propertyData,
          organizationId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
        dispatch(addProperty({ id: docRef.id, ...propertyData }));
        setSnackbarMessage('Property added successfully');
      } else {
        await updateDoc(doc(db, 'properties', selectedProperty.id), {
          ...propertyData,
          updatedAt: new Date().toISOString()
        });
        dispatch(updateProperty({ id: selectedProperty.id, ...propertyData }));
        setSnackbarMessage('Property updated successfully');
      }
      
      setSnackbarVariant('success');
      setSnackbarOpen(true);
      handleCloseDialog();
    } catch (err) {
      console.error('Error saving property:', err);
      dispatch(setError('Failed to save property'));
      setSnackbarMessage('Failed to save property');
      setSnackbarVariant('error');
      setSnackbarOpen(true);
    } finally {
      setSaving(false);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check file type
    if (!file.type.startsWith('image/')) {
      dispatch(setError('Please select an image file (JPEG, PNG, etc.)'));
      return;
    }

    // Check file size (limit to 5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > maxSize) {
      dispatch(setError('Image size should be less than 5MB'));
      return;
    }

    setSelectedFile(file);
    // Create a local preview URL
    const previewUrl = URL.createObjectURL(file);
    dispatch(setSelectedProperty({ ...selectedProperty, photo: previewUrl }));

    // Clean up the object URL when component unmounts
    return () => URL.revokeObjectURL(previewUrl);
  };

  const uploadPhoto = async (file) => {
    if (!file) return null;
    
    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExtension}`;
      const storageRef = ref(storage, `property-photos/${fileName}`);
      
      // Create file metadata including the content type
      const metadata = {
        contentType: file.type,
        customMetadata: {
          'uploaded-by': organizationId || 'unknown'
        }
      };

      // Upload the file and metadata
      const uploadTask = await uploadBytes(storageRef, file, metadata);
      const downloadURL = await getDownloadURL(uploadTask.ref);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error('Failed to upload image');
    }
  };

  const emptyProperty = {
    propertyName: '',
    propertyType: '',
    city: '',
    locality: '',
    rentPerMonth: '',
    securityDeposit: '',
    agreementStartDate: '',
    agreementEndDate: '',
    agreementTerm: '',
    operatorName: '',
    billableSeats: '',
    status: 'Active',
    photo: '',
    location: '',
    latitude: null,
    longitude: null,
    noticePeriod: '',
    lockinPeriod: '',
    rentalEscalation: '',
    securityDepositMonths: '',
    complimentaryBikeParking: '',
    complimentaryCarParking: '',
    carParkCost: '',
    bikeParkCost: '',
    centerTimings: '',
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  const handleOpenAddDialog = () => {
    setIsNewProperty(true);
    dispatch(clearSelectedProperty());
    dispatch(setEditDialogOpen(true));
  };

  const handleEditFieldChange = (event) => {
    const { name, value } = event.target;
    let updatedProperty = { ...selectedProperty, [name]: value };

    // Calculate agreement end date when start date or term changes
    if (name === 'agreementStartDate' || name === 'agreementTerm') {
      if (updatedProperty.agreementStartDate && updatedProperty.agreementTerm) {
        const startDate = new Date(updatedProperty.agreementStartDate);
        const endDate = new Date(startDate);
        endDate.setMonth(startDate.getMonth() + parseInt(updatedProperty.agreementTerm));
        updatedProperty.agreementEndDate = endDate.toISOString().split('T')[0];
      }
    }

    dispatch(setSelectedProperty(updatedProperty));
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleImportAgreement = (extractedData) => {
    // Open the Add Property dialog with pre-filled data
    dispatch(setSelectedProperty(extractedData));
    dispatch(setEditDialogOpen(true));
    setImportDialogOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    // Only allow Table tab (index 0) on desktop
    if (newValue === 0 && !isDesktop) {
      return;
    }
    setSelectedTab(newValue);
  };

  const handleExportCSV = () => {
    // Get the filtered and sorted data
    const dataToExport = filteredProperties;
    
    // Create CSV headers from the columns configuration
    const headers = columns.map(column => column.label);
    
    // Convert data to CSV format
    const csvData = dataToExport.map(row => {
      return columns.map(column => {
        const value = row[column.id];
        if (column.id === 'securityDeposit' || column.id === 'rentPerMonth') {
          return formatIndianRupees(value);
        }
        if (value === null || value === undefined) {
          return '';
        }
        return value.toString();
      });
    });
    
    // Combine headers and data
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');
    
    // Create blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'properties.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Property Card Component
  const PropertyCard = ({ property }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {property.photo ? (
          <CardMedia
            component="img"
            height="200"
            image={property.photo}
            alt={property.propertyName}
            sx={{ objectFit: 'cover' }}
          />
        ) : (
          <Box
            sx={{
              height: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.grey[200],
              color: theme.palette.text.secondary
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <BusinessIcon sx={{ fontSize: 40, mb: 1, opacity: 0.5 }} />
              <Typography variant="body2">No Image</Typography>
            </Box>
          </Box>
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h6" component="div" noWrap>
            {property.propertyName}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {property.propertyType}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {property.locality}, {property.city}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" color="primary">
              Rent: {formatIndianRupees(property.rentPerMonth)}
            </Typography>
            <Typography variant="subtitle2">
              Seats: {property.billableSeats}
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Chip
              size="small"
              label={property.status}
              color={property.status === 'Active' ? 'success' : 'default'}
              sx={{ mr: 1 }}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button 
            size="small" 
            startIcon={<EditIcon />}
            onClick={() => dispatch(setSelectedProperty(property))}
          >
            Edit
          </Button>
        </CardActions>
      </Card>
    );
  };

  const PropertyTabs = () => {
    return (
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          minHeight: 'unset',
          '& .MuiTabs-indicator': {
            backgroundColor: 'primary.main',
            height: 3
          },
        }}
      >
        <Tab
          icon={<ListView />}
          iconPosition="start"
          label="List View"
          value={0}
          sx={{ 
            textTransform: 'none',
            minHeight: 'unset',
            padding: '8px 16px',
            '&.Mui-selected': {
              color: 'primary.main',
            },
            '& .MuiTab-iconWrapper': {
              marginRight: 1,
            }
          }}
        />
        <Tab
          icon={<GridViewIcon />}
          iconPosition="start"
          label="Grid View"
          value={1}
          sx={{ 
            textTransform: 'none',
            minHeight: 'unset',
            padding: '8px 16px',
            '&.Mui-selected': {
              color: 'primary.main',
            },
            '& .MuiTab-iconWrapper': {
              marginRight: 1,
            }
          }}
        />
      </Tabs>
    );
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarVariant}
      />
      
        <Paper 
          sx={{ 
            height: '100%',
          }}
        >
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <PropertyTabs />
            <Box sx={{ flexGrow: 1 }} />

            <TextField
              size="small"
              placeholder="Search properties..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: 250 }}
            />

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={handleExportCSV}
                sx={{ textTransform: 'none' }}
              >
                Export
              </Button>
              {isDesktop && (
                <Button
                  variant="outlined"
                  startIcon={<UploadIcon />}
                  onClick={() => setImportDialogOpen(true)}
                  sx={{ textTransform: 'none' }}
                >
                  Import
                </Button>
              )}
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpenAddDialog}
                sx={{ textTransform: 'none' }}
              >
                Add Property
              </Button>
            </Box>
          </Toolbar>
          <Divider />
          {selectedTab === 0 ? (
            <Box sx={{ p: 2 }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth }}
                          sortDirection={orderBy === column.id ? order : false}
                          sx={{ 
                            fontWeight: 600,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={() => handleSort(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedProperties
                      .map((row) => (
                        <TableRow hover key={row.id}>
                          {columns.map((column) => (
                            <TableCell key={column.id}>
                              {column.format ? column.format(row[column.id]) : row[column.id]}
                            </TableCell>
                          ))}
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={() => handleEditProperty(row)}
                              aria-label="edit"
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredProperties.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          ) : (
            <Box sx={{ p: 2 }}>
              <Grid container spacing={3}>
                {paginatedProperties
                  .map((property) => (
                    <Grid item xs={12} sm={6} md={4} key={property.id}>
                      <PropertyCard property={property} />
                    </Grid>
                  ))}
              </Grid>
              <Box sx={{ mt: 2 }}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredProperties.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          )}
          <Dialog 
            open={editDialogOpen} 
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              {isNewProperty ? 'Add New Property' : 'Edit Property'}
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {selectedProperty?.photo && (
                      <Box sx={{ mb: 2, width: '100%', maxWidth: 300, height: 200, position: 'relative' }}>
                        <CardMedia
                          component="img"
                          image={selectedProperty.photo}
                          alt="Property"
                          sx={{ 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'cover',
                            borderRadius: 1
                          }}
                        />
                      </Box>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => fileInputRef.current.click()}
                      disabled={uploading}
                      startIcon={uploading ? <CircularProgress size={20} /> : null}
                    >
                      {selectedProperty?.photo ? 'Change Photo' : 'Upload Photo'}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Property Name"
                    name="propertyName"
                    value={selectedProperty?.propertyName || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Property Type</InputLabel>
                    <Select
                      name="propertyType"
                      value={selectedProperty?.propertyType || ''}
                      onChange={handleEditFieldChange}
                      label="Property Type"
                    >
                      {Object.entries(PROPERTY_TYPES).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={selectedProperty?.city || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Locality"
                    name="locality"
                    value={selectedProperty?.locality || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Micromarket"
                    name="micromarket"
                    value={selectedProperty?.micromarket || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Rent per Month"
                    name="rentPerMonth"
                    type="number"
                    value={selectedProperty?.rentPerMonth || ''}
                    onChange={handleEditFieldChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Security Deposit"
                    name="securityDeposit"
                    type="number"
                    value={selectedProperty?.securityDeposit || ''}
                    onChange={handleEditFieldChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Agreement Start Date"
                    name="agreementStartDate"
                    type="date"
                    value={selectedProperty?.agreementStartDate || ''}
                    onChange={handleEditFieldChange}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Agreement Term (months)"
                    name="agreementTerm"
                    type="number"
                    value={selectedProperty?.agreementTerm || ''}
                    onChange={handleEditFieldChange}
                    helperText="Duration of agreement in months"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Agreement End Date"
                    type="date"
                    value={selectedProperty?.agreementEndDate || ''}
                    InputLabelProps={{ shrink: true }}
                    disabled
                    helperText="Auto-calculated based on start date and term"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Operator Name"
                    name="operatorName"
                    value={selectedProperty?.operatorName || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Billable Seats"
                    name="billableSeats"
                    type="number"
                    value={selectedProperty?.billableSeats || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Status</InputLabel>
                    <Select
                      name="status"
                      value={selectedProperty?.status || 'Active'}
                      onChange={handleEditFieldChange}
                      label="Status"
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Notice Period (months)"
                    name="noticePeriod"
                    type="number"
                    value={selectedProperty?.noticePeriod || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Lock-in Period (months)"
                    name="lockinPeriod"
                    type="number"
                    value={selectedProperty?.lockinPeriod || ''}
                    onChange={handleEditFieldChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Rental Escalation (%)"
                    name="rentalEscalation"
                    type="number"
                    value={selectedProperty?.rentalEscalation || ''}
                    onChange={handleEditFieldChange}
                    required
                    helperText="Annual rental increase percentage"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Security Deposit (months)"
                    name="securityDepositMonths"
                    type="number"
                    value={selectedProperty?.securityDepositMonths || ''}
                    onChange={handleEditFieldChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complimentary Bike Parking"
                    name="complimentaryBikeParking"
                    type="number"
                    value={selectedProperty?.complimentaryBikeParking || ''}
                    onChange={handleEditFieldChange}
                    helperText="Number of complimentary bike parking spots"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complimentary Car Parking"
                    name="complimentaryCarParking"
                    type="number"
                    value={selectedProperty?.complimentaryCarParking || ''}
                    onChange={handleEditFieldChange}
                    helperText="Number of complimentary car parking spots"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Car Park Cost"
                    name="carParkCost"
                    type="number"
                    value={selectedProperty?.carParkCost || ''}
                    onChange={handleEditFieldChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                    helperText="Cost per car parking spot"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bike Park Cost"
                    name="bikeParkCost"
                    type="number"
                    value={selectedProperty?.bikeParkCost || ''}
                    onChange={handleEditFieldChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                    helperText="Cost per bike parking spot"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Center Timings"
                    name="centerTimings"
                    value={selectedProperty?.centerTimings || ''}
                    onChange={handleEditFieldChange}
                    helperText="e.g., Mon-Fri: 9 AM - 6 PM"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button
                onClick={() => handleSaveProperty(selectedProperty)}
                variant="contained"
                disabled={saving}
                startIcon={saving ? <CircularProgress size={20} /> : null}
              >
                {isNewProperty ? 'Add Property' : 'Save Changes'}
              </Button>
            </DialogActions>
          </Dialog>

          <ImportAgreementDialog
            open={importDialogOpen}
            onClose={() => setImportDialogOpen(false)}
            onImport={handleImportAgreement}
          />
        </Paper>
      
    </Box>
  );
}

export default Properties;
