import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress
} from '@mui/material';
import {
  Business as PropertyIcon,
  Close as CloseIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { setProperties, setLoading, setError } from '../store/slices/propertySlice';

function CompareProperties() {
  const dispatch = useDispatch();
  const { properties, loading: propertiesLoading } = useSelector((state) => state.property);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [averages, setAverages] = useState({});

  /*
  useEffect(() => {
    dispatch(setLoading(true));
    const q = query(collection(db, 'properties'));
    const unsubscribe = onSnapshot(q, 
      (querySnapshot) => {
        const propertiesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        dispatch(setProperties(propertiesData));
        dispatch(setLoading(false));
      },
      (error) => {
        console.error("Error fetching properties:", error);
        dispatch(setError(error.message));
        dispatch(setLoading(false));
      }
    );

    return () => unsubscribe();
  }, [dispatch]);
  */

  useEffect(() => {
    if (selectedProperties.length > 0) {
      // Calculate averages for comparison fields
      const newAverages = {
        rentPerSeat: selectedProperties.reduce((acc, prop) => 
          acc + (prop.rentPerMonth / prop.billableSeats), 0) / selectedProperties.length,
        agreementTerm: selectedProperties.reduce((acc, prop) => 
          acc + Number(prop.agreementTerm), 0) / selectedProperties.length,
        lockinPeriod: selectedProperties.reduce((acc, prop) => 
          acc + Number(prop.lockinPeriod), 0) / selectedProperties.length,
        noticePeriod: selectedProperties.reduce((acc, prop) => 
          acc + Number(prop.noticePeriod), 0) / selectedProperties.length,
        rentalEscalation: selectedProperties.reduce((acc, prop) => 
          acc + Number(prop.rentalEscalation), 0) / selectedProperties.length,
      };
      setAverages(newAverages);
    }
  }, [selectedProperties]);

  const getComparisonColor = (value, field) => {
    if (!averages[field] || selectedProperties.length < 2) return 'white';
    
    const avg = averages[field];
    const isBelow = value < avg;
    
    // For these metrics, below average is good (green)
    const belowIsGood = ['rentPerSeat', 'agreementTerm', 'lockinPeriod', 'noticePeriod', 'rentalEscalation'];
    
    if (belowIsGood.includes(field)) {
      return isBelow ? '#e8f5e9' : '#ffebee';  // Light green : Light red
    }
    
    return isBelow ? '#ffebee' : '#e8f5e9';  // Light red : Light green
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    setActiveId(null);
    const { active, over } = event;

    if (over && over.id === 'comparison') {
      const propertyToAdd = properties.find(p => p.id === active.id);
      if (propertyToAdd && !selectedProperties.some(p => p.id === propertyToAdd.id)) {
        setSelectedProperties(prev => [...prev, propertyToAdd]);
      }
    }
  };

  const handleRemoveFromComparison = (propertyId) => {
    setSelectedProperties(prev => prev.filter(p => p.id !== propertyId));
  };

  if (propertiesLoading) {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}>
        <CircularProgress />
      </Box>
    );
  }

  const comparisonFields = [
    { label: 'Property Name', field: 'propertyName' },
    { label: 'Property Type', field: 'propertyType' },
    { label: 'Location', field: 'city' },
    { label: 'Billable Seats', field: 'billableSeats' },
    { 
      label: 'Rent per Seat', 
      field: 'rentPerSeat',
      getValue: (property) => Math.round(property.rentPerMonth / property.billableSeats),
      display: (value) => `₹${value.toLocaleString()}`
    },
    { 
      label: 'Agreement Term', 
      field: 'agreementTerm',
      getValue: (property) => property.agreementTerm,
      display: (value) => `${value} months`
    },
    { 
      label: 'Lock-in Period', 
      field: 'lockinPeriod',
      getValue: (property) => property.lockinPeriod,
      display: (value) => `${value} months`
    },
    { 
      label: 'Notice Period', 
      field: 'noticePeriod',
      getValue: (property) => property.noticePeriod,
      display: (value) => `${value} months`
    },
    { 
      label: 'Security Deposit', 
      getValue: (property) => `₹${property.securityDeposit.toLocaleString()}`
    },
    { 
      label: 'Escalation', 
      field: 'rentalEscalation',
      getValue: (property) => property.rentalEscalation,
      display: (value) => `${value}%`
    }
  ];

  const PropertyCard = ({ property, isDragging }) => (
    <Card
      sx={{
        cursor: 'grab',
        '&:hover': {
          boxShadow: 4,
          bgcolor: 'action.hover',
        },
        transition: 'all 0.2s',
        opacity: selectedProperties.some(p => p.id === property.id) ? 0.6 : 1,
        ...(isDragging && {
          boxShadow: 8,
        }),
      }}
    >
      <CardContent sx={{ p: 1.5, '&:last-child': { pb: 1.5 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <DragIndicatorIcon 
            sx={{ 
              mr: 0.5, 
              fontSize: '1.2rem',
              color: 'text.secondary',
              cursor: 'grab',
              '&:hover': { color: 'primary.main' }
            }} 
          />
          <PropertyIcon sx={{ mr: 0.5, fontSize: '1.2rem' }} />
          <Typography variant="subtitle1" component="div" noWrap>
            {property.propertyName}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
          <Typography variant="body2" color="text.secondary" noWrap>
            {property.city}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {property.billableSeats} seats
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ height: 'calc(100vh - 64px)', display: 'flex' }}>
      {/* Left Panel - Property List */}
      <Box 
        sx={{ 
          width: '25%', 
          maxWidth: '300px',
          borderRight: 1, 
          borderColor: 'divider',
          p: 2,
          overflowY: 'auto'
        }}
      >
        <Typography variant="h6" gutterBottom>
          Available Properties
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          {properties.map((property) => (
            <Box
              key={property.id}
              data-id={property.id}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('text/plain', property.id);
                handleDragStart({ active: { id: property.id } });
              }}
            >
              <PropertyCard property={property} isDragging={activeId === property.id} />
            </Box>
          ))}
        </Box>
      </Box>

      {/* Right Panel - Comparison */}
      <Box 
        sx={{ 
          flex: 1,
          p: 3,
          overflowY: 'auto',
          bgcolor: 'background.default'
        }}
        id="comparison"
        onDragOver={(e) => {
          e.preventDefault();
          e.currentTarget.style.borderColor = 'primary.main';
        }}
        onDragLeave={(e) => {
          e.currentTarget.style.borderColor = 'divider';
        }}
        onDrop={(e) => {
          e.preventDefault();
          const propertyId = e.dataTransfer.getData('text/plain');
          handleDragEnd({
            active: { id: propertyId },
            over: { id: 'comparison' }
          });
          e.currentTarget.style.borderColor = 'divider';
        }}
      >
        <Typography variant="h5" gutterBottom>
          Property Comparison
        </Typography>
        
        {selectedProperties.length === 0 ? (
          <Paper 
            sx={{ 
              p: 3, 
              textAlign: 'center',
              bgcolor: 'background.default',
              border: '2px dashed',
              borderColor: 'divider',
              transition: 'all 0.2s',
            }}
          >
            <Typography color="text.secondary">
              Drag properties here to compare
            </Typography>
          </Paper>
        ) : (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', width: '200px' }}>
                    Comparison Factors
                  </TableCell>
                  {selectedProperties.map((property) => (
                    <TableCell key={property.id} align="center">
                      <Box sx={{ position: 'relative', pr: 4 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {property.propertyName}
                        </Typography>
                        <IconButton
                          size="small"
                          sx={{
                            position: 'absolute',
                            top: -12,
                            right: -12,
                          }}
                          onClick={() => handleRemoveFromComparison(property.id)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {comparisonFields.map((field) => (
                  <TableRow key={field.label}>
                    <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', bgcolor: 'action.hover' }}>
                      {field.label}
                    </TableCell>
                    {selectedProperties.map((property) => {
                      const value = field.getValue ? field.getValue(property) : property[field.field];
                      return (
                        <TableCell 
                          key={property.id} 
                          align="center"
                          sx={{ 
                            bgcolor: field.field ? getComparisonColor(value, field.field) : 'white',
                            transition: 'background-color 0.3s'
                          }}
                        >
                          {field.display ? field.display(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {activeId ? (
        <Box sx={{ position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
          <PropertyCard 
            property={properties.find(p => p.id === activeId)} 
            isDragging={true}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default CompareProperties;
