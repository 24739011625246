import { createTheme } from '@mui/material/styles';

const getTheme = (mode) => createTheme({
  palette: {
    mode,
    primary: {
      main: '#2193B0',
      light: '#6EC2D7',
      dark: '#17667A',
      contrastText: '#fff',
    },
    success: {
      main: '#10B981',
      light: '#34D399',
      dark: '#059669',
      lighter: '#ECFDF5',
    },
    warning: {
      main: '#F59E0B',
      light: '#FBBF24',
      dark: '#D97706',
      lighter: '#FEF3C7',
    },
    error: {
      main: '#EF4444',
      light: '#F87171',
      dark: '#DC2626',
      lighter: '#FEE2E2',
    },
    info: {
      main: '#6366F1',
      light: '#818CF8',
      dark: '#4F46E5',
      lighter: '#EEF2FF',
    },
    background: {
      default: mode === 'light' ? '#F9FAFB' : '#121212',
      paper: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
    },
    text: {
      primary: mode === 'light' ? '#111827' : '#FFFFFF',
      secondary: mode === 'light' ? '#6B7280' : '#B0B0B0',
    },
    divider: mode === 'light' ? '#E5E7EB' : '#2D2D2D',
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.1rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'light' ? '#FFFFFF' : '#121212',
          color: mode === 'light' ? '#111827' : '#FFFFFF',
          boxShadow: mode === 'light' ? '0 1px 3px rgba(0,0,0,0.05)' : 'none',
          borderBottom: mode === 'light' ? '1px solid #E5E7EB' : '1px solid #2D2D2D',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
          borderRight: mode === 'light' ? '1px solid #E5E7EB' : '1px solid #2D2D2D',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          borderRadius: '6px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderColor: '#013F2E',
          color: '#013F2E',
          '&:hover': {
            backgroundColor: mode === 'light' ? 'rgba(1, 63, 46, 0.04)' : 'rgba(255, 255, 255, 0.04)',
            borderColor: '#002A1F',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'light' ? '#F9FAFB' : '#1E1E1E',
          '& .MuiTableCell-root': {
            color: mode === 'light' ? '#6B7280' : '#B0B0B0',
            fontWeight: 500,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: mode === 'light' ? '1px solid #E5E7EB' : '1px solid #2D2D2D',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: mode === 'light' ? '0px 1px 3px rgba(0, 0, 0, 0.1)' : 'none',
          borderRadius: '0.75rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: mode === 'light' ? '0px 1px 3px rgba(0, 0, 0, 0.1)' : 'none',
          borderRadius: '0.75rem',
        },
        elevation1: {
          boxShadow: mode === 'light' ? '0 1px 3px rgba(0,0,0,0.05)' : 'none',
          border: mode === 'light' ? '1px solid #E5E7EB' : '1px solid #2D2D2D',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
        warningColor: {
          backgroundColor: '#FEF3C7',
          color: '#D97706',
        },
        errorColor: {
          backgroundColor: '#FEE2E2',
          color: '#DC2626',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: mode === 'light' ? '#F9FAFB' : 'rgba(255, 255, 255, 0.04)',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '& .MuiListItemIcon-root': {
              color: '#111827',
            },
            '& .MuiListItemText-primary': {
              color: '#111827',
            },
          },
          '&.Mui-selected': {
            backgroundColor: '#3B82F6',
            '&:hover': {
              backgroundColor: '#2563EB',
            },
            '& .MuiListItemIcon-root': {
              color: '#FFFFFF',
            },
            '& .MuiListItemText-primary': {
              color: '#FFFFFF',
              fontWeight: 600,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: mode === 'light' ? '#6B7280' : '#9CA3AF',
          minWidth: '40px',
          transition: 'color 0.2s ease-in-out',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: mode === 'light' ? '#6B7280' : '#9CA3AF',
          fontSize: '0.875rem',
          fontWeight: 500,
          transition: 'color 0.2s ease-in-out',
        },
      },
    },
  },
});

export default getTheme;
